import { flexCenter } from 'config/mixins';
import theme from 'config/theme';
import styled from 'styled-components';

const { fontSizes, fontWeights, colors, radii, lineHeight, fonts, boxShadow } = theme;

export const ImageShimmer = styled.div`
	background-color: gray;
	height: 200px;
	width: 100%;
	border-top-right-radius: 16px;
	border-top-left-radius: 16px;
	animation: shimmer 4s infinite;
	background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
	background-size: 1000px 100%;

	@keyframes shimmer {
		0% {
			background-position: -1000px 0;
		}
		100% {
			background-position: 1000px 0;
		}
	}
`;

export const TextShimmer = styled(ImageShimmer)`
	background-color: gray;
	height: 1em;
	margin-top: 15px;
	width: ${({ width }) => (width ? width : '100px')};
	border-radius: 0;
`;

export const Section = styled.section`
	background-color: ${({ backgroundColor }) =>
		backgroundColor ? backgroundColor : `${colors.background}`};
	${flexCenter};
	flex-direction: column;
	padding: 48px 16px;
	padding-bottom: 24px;
	padding-top: 40px;
`;

export const Title = styled.h2`
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.xxlarge};
	font-family: ${fonts.Gilroy};
	line-height: 1.2;
	margin-bottom: 24px;
	text-align: center;

	@media (max-width: 500px) {
		font-size: ${fontSizes.xlarge};
		width: 100%;
	}
`;

export const NavContainer = styled.div`
	display: flex;
	overflow-x: auto;
	flex-wrap: nowrap;
	margin-bottom: 24px;

	@media (max-width: 750px) {
		width: 100%;
		::-webkit-scrollbar {
			display: none;
		}
	}
`;

export const NavItem = styled.li`
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.large};
	color: ${colors.darkBlue};
	text-align: center;
	padding: 16px;
	cursor: pointer;

	${({ active }) =>
		active &&
		`
    color: ${colors.pink};
    & ${Underline} {
      display: block;
    }`}

	@media (max-width: 500px) {
		font-size: ${fontSizes.medium};
		white-space: nowrap;
		flex: 0 auto;
	}
`;

export const Underline = styled.div`
	background-color: ${colors.pink};
	border-radius: 10px;
	margin-top: 16px;
	display: none;
	width: 32px;
	height: 4px;
`;

export const CardContainer = styled.div`
	${flexCenter};
	flex-wrap: wrap;
	max-width: 1200px;
	margin-bottom: 0;
	padding-bottom: 0;

	@media (max-width: 500px) {
		justify-content: unset;
		flex-wrap: nowrap;
		overflow-y: hidden;
		overflow-x: auto;
		width: 100%;
		::-webkit-scrollbar {
			display: none;
		}
	}
`;

export const Card = styled.div`
	border-radius: ${radii.default};
	min-width: 300px;
	max-width: 400px;
	width: 30%;
	height: 440px;
	margin-right: 24px;
	margin-bottom: 24px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: center;

	box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
	transition: box-shadow 200ms ease-in-out;
	background-blend-mode: darken;

	cursor: pointer;

	&:hover {
		box-shadow: ${boxShadow.default};
	}

	@media (max-width: 500px) {
		flex: 0 auto;
		margin: 20px 10px;
	}
`;

export const ImageContainer = styled.div``;

export const Img = styled.img`
	border-top-right-radius: ${radii.default};
	border-top-left-radius: ${radii.default};
	object-fit: cover;
	height: 230px;
	width: 100%;
`;

export const InfoContainer = styled.div`
	padding: 10px 20px;
`;

export const MajorType = styled.p`
	font-weight: ${fontWeights.bold};
	color: ${colors.pink};
	width: 90%;
	text-transform: capitalize;
`;

export const Address = styled.p`
	font-weight: ${fontWeights.semi};
	font-size: ${fontSizes.small};
	color: ${colors.darkGray};
	margin-bottom: 12px;
	line-height: 1;
	height: 42px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const CardTitle = styled.h3`
	line-height: ${lineHeight.tight};
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.large};
	color: ${colors.darkBlue};
	margin-bottom: 12px;
	height: 50px;

	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`;

export const SubInfo = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
`;

export const Info = styled.div`
	font-weight: ${fontWeights.semi};
`;

export const Icon = styled.img`
	width: 20px;
	height: 18px;
	margin-left: 10px;
	margin-right: 10px;
`;

export const Button = styled.a`
	border: 2px solid ${colors.darkBlue};
	background-color: ${colors.white};
	font-weight: ${fontWeights.bold};
	border-radius: ${radii.default};
	font-size: ${fontSizes.medium};
	color: ${colors.darkBlue};

	text-align: center;
	padding: 25px 10px;
	max-width: 360px;
	min-width: 300px;
	width: 30%;

	cursor: pointer;

	@media (max-width: 500px) {
		width: 100%;
		max-width: unset;
		min-width: unset;
	}
`;

export const CardLink = styled.a.attrs({
	target: '_blank',
	rel: 'noopener noreferrer',
})`
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.large};
	color: ${colors.white};
	text-decoration: none;
`;

import React from 'react';

import { Breakpoint } from '@tiaanduplessis/react-resize';

import api from 'api';
import authContainer from 'containers/auth';
import modalContainer from 'containers/modal';
import { NavContainer, NavItem, Section, Title, Underline, CardContainer } from './styles';
import { ModalType } from 'constants/modalTypes';
import ListingCardMobile from '../../digs-search-v2/ListingCardMobile';
import { listingService } from 'services/listingService';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';

class PopularLocations extends React.Component {
	state = {
		popularLocations: [],
		active: 0,
		listings: [],
		loading: true,
	};

	componentDidMount = () => {
		this.fetchPopularLocations();
		authContainer.subscribe(this.onAuthContainerStateChange);
	};

	componentWillUnmount = () => {
		authContainer.unsubscribe(this.onAuthContainerStateChange);
	};

	onAuthContainerStateChange = () => {
		if (!authContainer.state.token) {
			this.setState(({ listings }) => ({
				listings: listings.map(item => ({
					...item,
					favourite: false,
				})),
			}));
			return;
		}

		this.fetchPopularLocations();
	};

	fetchPopularLocations = async () => {
		api.contentV2
			.getPopularLocations(this.props.block)
			.then(res => res.data || [])
			.then(data => {
				this.setState({
					popularLocations: data,
					active: data[0] && data[0].location_id,
					listings: (data[0] && data[0].results) || [],
					loading: false,
				});
			});
	};

	clickMenu = id => {
		this.setState({ loading: true });
		const { popularLocations } = this.state;
		const listings = popularLocations.find(pl => pl.location_id === id);

		this.setState({
			active: id,
			listings: listings.results,
			loading: false,
		});
	};

	handleListingCardClick = listing => {
		const link = listingService.getViewListingLink(listing);
		window.open(link, '_self');
	};

	handleFavouriteClick = listing => {
		if (!authContainer.state.token) {
			modalContainer.set(ModalType.Login);
			return;
		}

		if (listing.favourite) {
			api.favouritesV2.deleteFavourite(listing.uuid);
			this.setState(({ listings }) => ({
				listings: listings.map(item => ({
					...item,
					favourite: item.uuid === listing.uuid ? false : item.favourite,
				})),
			}));
			return;
		}

		api.favouritesV2.createFavourite(listing.uuid);
		this.setState(({ listings }) => ({
			listings: listings.map(item => ({
				...item,
				favourite: item.uuid === listing.uuid ? true : item.favourite,
			})),
		}));
	};

	renderListingCards = (widthPx, height) => {
		const { loading, listings } = this.state;

		return listings.map(listing => (
			<ListingCardMobile
				key={listing.uuid}
				{...listing}
				loading={loading}
				onClick={() => this.handleListingCardClick(listing)}
				widthPx={widthPx}
				fixedHeightPx={height}
			/>
		));
	};

	render() {
		const { active, popularLocations } = this.state;

		return (
			<>
				<Section backgroundColor={this.props.backgroundColor}>
					<Title>{this.props.title}</Title>
					<NavContainer>
						{popularLocations.map(location => (
							<NavItem
								active={active === location.location_id}
								onClick={() => this.clickMenu(location.location_id)}
							>
								{location.location_name}
								<Underline />
							</NavItem>
						))}
					</NavContainer>
					<CardContainer>
						<Breakpoint maxWidth={BREAKPOINT_MOBILE_PX}>
							{this.renderListingCards(320, 360)}
						</Breakpoint>
						<Breakpoint minWidth={BREAKPOINT_MOBILE_PX + 1}>
							{this.renderListingCards(320, 360)}
						</Breakpoint>
					</CardContainer>
				</Section>
			</>
		);
	}
}

export default React.memo(PopularLocations);

import React from 'react';

import { Breakpoint } from '@tiaanduplessis/react-resize';

import api from 'api';

import { Section, Title, CardContainer, CityTitle } from './styles';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';

class PopularCities extends React.Component {
	state = {
		popularCities: [],
	};

	componentDidMount = () => {
		this.fetchPopularLocations();
	};

	fetchPopularLocations = async () => {
		api.contentV2
			.getPopularLocations(5)
			.then(res => res.data || [])
			.then(data => {
				this.setState({
					popularCities: data,
				});
			});
	};

	handleListingCardClick = listing => {
		window.open(`/digs-search?locations=${listing.location_id}`, '_self');
	};

	renderListingCards = () => {
		const { popularCities } = this.state;

		return popularCities.map(listing => (
			<CityTitle onClick={() => this.handleListingCardClick(listing)}>
				{listing.location_name}
			</CityTitle>
		));
	};

	render() {
		return (
			<>
				<Section>
					<Title>Looking for popular cities?</Title>
					<CardContainer>
						<Breakpoint maxWidth={BREAKPOINT_MOBILE_PX}>{this.renderListingCards()}</Breakpoint>
						<Breakpoint minWidth={BREAKPOINT_MOBILE_PX + 1}>{this.renderListingCards()}</Breakpoint>
					</CardContainer>
				</Section>
			</>
		);
	}
}

export default React.memo(PopularCities);

import { flexCenter } from 'config/mixins';
import theme from 'config/theme';
import styled from 'styled-components';

const { fontSizes, fontWeights, colors, radii, lineHeight, fonts, boxShadow } = theme;

export const Section = styled.section`
	background-color: white;
	${flexCenter};
	flex-direction: column;
	padding: 48px 16px;
	padding-bottom: 0;
`;

export const Title = styled.h2`
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.xxlarge};
	font-family: ${fonts.Gilroy};
	margin-bottom: 24px;
	text-align: center;

	@media (max-width: 500px) {
		font-size: ${fontSizes.xlarge};
		width: 100%;
	}
`;

export const ImageText = styled.h3`
	color: ${colors.white};
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.xlarge};
	font-family: ${fonts.Gilroy};
	margin-bottom: 24px;
	position: absolute;
	bottom: 0;

	@media (max-width: 500px) {
		margin-bottom: 10px;
		font-size: ${fontSizes.medium};
	}
`;

export const SubTitle = styled.h3`
	font-weight: ${fontWeights.normal};
	font-size: ${fontSizes.xlarge};
	font-family: ${fonts.Gilroy};
	margin-bottom: 24px;
	text-align: center;

	@media (max-width: 500px) {
		font-size: ${fontSizes.xlarge};
		width: 100%;
	}
`;

export const CardContainer = styled.div`
	${flexCenter};
	flex-wrap: wrap;
	max-width: 1200px;
	margin-bottom: 40px;
	padding-bottom: 20px;
`;

export const SlideImage = styled.img`
	height: 100%;
	width: 100%;
	display: block;
	object-fit: cover;
`;

export const Container = styled.div`
	border-radius: 16px;

	background-color: white;
	margin: 8px;
	margin-bottom: 8px;
	overflow: hidden;
	box-shadow: ${boxShadow.small};
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	transform: translateZ(0);
	position: relative;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	${({ widthPx }) => (widthPx ? `width: ${widthPx}px; min-width: ${widthPx}px;` : '')}
	${({ heightPx }) => (heightPx ? `height: ${heightPx}px;` : '')}

	@media (max-width: 500px) {
		margin: 6px;
		min-height: 50px;
		${({ widthPx }) => (widthPx ? `width: ${widthPx}px; min-width: ${widthPx}px;` : '')}
		${({ heightPx }) => (heightPx ? `height: ${heightPx}px;` : '')}
	}
`;

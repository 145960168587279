import React, { useEffect, useRef } from 'react';

import styled from 'styled-components';
import theme from 'config/theme';

const { colors } = theme;

declare var window: any;

const idNumber = '728773';
const accountNumber = 187151;

const HomepageAdBlock: React.FC = () => {
	const adRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (window.AdButler) {
			loadAd();
		} else {
			const script = document.createElement('script');
			script.src = 'https://servedbyadbutler.com/app.js';
			script.async = true;
			script.onload = loadAd;
			document.body.appendChild(script);
		}

		function loadAd() {
			var AdButler = window.AdButler || {};
			AdButler.ads = AdButler.ads || [];
			var abkw = window.abkw || '';
			var plcID = window['plc' + idNumber] || 0;
			var div = document.createElement('div');
			div.id = 'placement_' + idNumber + '_' + plcID;
			adRef.current?.appendChild(div);

			AdButler.ads.push({
				handler: function(opt: any) {
					AdButler.register(
						accountNumber,
						+idNumber,
						[],
						'placement_' + idNumber + '_' + opt.place,
						opt,
					);
				},
				opt: {
					place: plcID++,
					keywords: abkw,
					domain: 'servedbyadbutler.com',
					click: 'CLICK_MACRO_PLACEHOLDER',
				},
			});
		}
	}, []);

	return (
		<Container>
			<Ad ref={adRef} />
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${colors.white};
	width: 100%;
`;

const Ad = styled.div`
	img {
		display: block;
	}

	border-radius: 12px;
	margin-top: 24px;
	margin-left: 16px;
	margin-right: 16px;
	margin-bottom: 0;
	max-width: 1000px;
	overflow: hidden;
`;

export default HomepageAdBlock;

import React from 'react';

import { Breakpoint } from '@tiaanduplessis/react-resize';

import api from 'api';

import {
	Section,
	Title,
	CardContainer,
	SubTitle,
	Container,
	SlideImage,
	ImageText,
} from './styles';
import { Errors } from 'constants/errors';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';

class TopCountries extends React.Component {
	state = {
		popularLocations: [],
	};

	componentDidMount = () => {
		this.fetchPopularLocations();
	};

	fetchPopularLocations = async () => {
		api.contentV2
			.getPopularLocations(3)
			.then(res => res.data || [])
			.then(data => {
				this.setState({
					popularLocations: data,
				});
			});
	};

	handleListingCardClick = listing => {
		window.open(`/digs-search?locations=${listing.location_id}`, '_self');
	};

	renderListingCards = (widthPx, height) => {
		const { popularLocations } = this.state;

		return popularLocations.map(listing => (
			<Container
				onClick={() => this.handleListingCardClick(listing)}
				widthPx={widthPx}
				heightPx={height}
			>
				<SlideImage
					src={`${listing.image_url.split('?')[0]}?auto=compress&auto=format`}
					alt={Errors.img_alt_error}
				/>
				<ImageText>{listing.location_name}</ImageText>
			</Container>
		));
	};

	render() {
		return (
			<>
				<Section>
					<Title>Our top Countries</Title>
					<SubTitle>Discover accommodation around the world</SubTitle>
					<CardContainer>
						<Breakpoint maxWidth={BREAKPOINT_MOBILE_PX}>
							{this.renderListingCards(160, 130)}
						</Breakpoint>
						<Breakpoint minWidth={BREAKPOINT_MOBILE_PX + 1}>
							{this.renderListingCards(300, 200)}
						</Breakpoint>
					</CardContainer>
				</Section>
			</>
		);
	}
}

export default React.memo(TopCountries);

import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { Button } from 'lib/Button';
import routes from 'config/routes';

import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';

const { colors, fontWeights, fontSizes } = theme;

const GetInTouchSection = () => (
	<Container>
		<Title>Get in touch!</Title>
		<Text>
			We’d love to hear from you, or answer any questions! Feel free to get in touch with us.
		</Text>
		<Button isPink link={routes.contact_us}>
			Contact us
		</Button>
	</Container>
);

export default GetInTouchSection;

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	background: ${featureFlagContainer.isEnabled(FeatureFlag.Simplify)
		? colors.grey01
		: colors.grey10};
	padding: 48px 16px;
`;

export const Title = styled.h2`
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.xxlarge};
	margin-bottom: 24px;
	text-align: center;
	line-height: 1.2;

	@media (max-width: 500px) {
		font-size: ${fontSizes.xlarge};
		width: 100%;
	}
`;

const Text = styled.p`
	font-size: 20px;
	color: ${colors.darkBlue};
	margin-bottom: 40px;
	margin-left: 16px;
	margin-right: 16px;
	max-width: 500px;
`;

import { flexCenter } from 'config/mixins';
import theme from 'config/theme';
import styled from 'styled-components';

const { fontSizes, fontWeights, colors, radii, lineHeight, fonts, boxShadow } = theme;

export const Section = styled.section`
	background-color: ${colors.background};
	${flexCenter};
	flex-direction: column;
	padding: 48px 24px;
	padding-bottom: 0;
`;

export const Title = styled.h2`
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.xxlarge};
	font-family: ${fonts.Gilroy};
	text-align: center;

	@media (max-width: 500px) {
		font-size: ${fontSizes.xlarge};
		width: 100%;
	}
`;

export const CityTitle = styled.h3`
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.medium};
	font-family: ${fonts.Gilroy};
	margin-bottom: 24px;
	margin: 14px;
	overflow: hidden;
	cursor: pointer;

	@media (max-width: 500px) {
		font-size: ${fontSizes.medium};
		width: 110px;
		display: flex;
		margin: 20px;
		flex-direction: column;
		align-items: flex-start;
		cursor: pointer;
		transform: translateZ(0);
		position: relative;
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		${({ widthPx }) => (widthPx ? `width: ${widthPx}px; min-width: ${widthPx}px;` : '')}
		${({ heightPx }) => (heightPx ? `height: ${heightPx}px;` : '')}
	}
`;

export const CardContainer = styled.div`
	${flexCenter};
	flex-wrap: wrap;
	max-width: 1100px;
	margin-top: 40px;
	margin-bottom: 40px;
	padding-bottom: 20px;
	padding-right: 30px;
	padding-left: 30px;

	@media (max-width: 500px) {
		padding-right: 0px;
		padding-left: 0px;
		align-items: center;
		justify-content: center;
`;

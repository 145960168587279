import React from 'react';
import theme from 'config/theme';
import styled from 'styled-components';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';

const { colors } = theme;

interface CustomAdBannerProps {
	imageUrl: string;
	link: string;
}

const CustomAdBanner = ({ imageUrl, link }: CustomAdBannerProps) => {
	return (
		<Container>
			<Link href={link} target="_blankF">
				<Image src={imageUrl} />
			</Link>
		</Container>
	);
};

export default CustomAdBanner;

const Image = styled.img`
	max-width: 900px;
	width: 100%;
`;

const Link = styled.a``;

const Container = styled.div`
	padding: 16px;
	padding-top: 32px;
	padding-bottom: 0;
	background: ${colors.white};
	display: flex;
	justify-content: center;

	@media (max-width: ${BREAKPOINT_MOBILE_PX + 1}px) {
		padding-top: 16px;
	}
`;
